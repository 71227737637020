import { Formik } from "formik";
import { FC } from "react";
import {
  Case,
  CaseSchema as validationSchema,
} from "auditaware-types";
import { useUpdateCase } from "../../../hooks/cases";
import useHandleSubmit from "../../../hooks/useHandleSubmitCase";
import FeesForm from "./FeesForm";

type EditFeesProps = {
    caseData: Case;
    closeDrawer: () => void;
};

const EditFees: FC<EditFeesProps> = ({ caseData, closeDrawer }) => {
  const caseId = caseData.id;
  const [updateCase] = useUpdateCase(caseId);
  const handleSubmit = useHandleSubmit(updateCase, caseData.subject, closeDrawer);

  const initialValues = {
    ...caseData,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FeesForm closeDrawer={closeDrawer} />
    </Formik>
  );
};

export default EditFees;

import { FC, useCallback, useMemo, useState } from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColumnGroupingModel,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { DataGridToolbar } from "./DataGridToolbar";
import { TableContainer, styled } from "@mui/material";
import { usePersistentState } from "../../hooks/usePersistentState";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-withBorderColor": {
    borderColor:
      theme.palette.mode === "dark" // eslint-disable-next-line operator-linebreak
        ? "#000" // eslint-disable-next-line operator-linebreak
        : "rgba(0, 0, 0, 0.08)",
  },
}));

type DataGridProps = DataGridProProps & {
  rows: GridValidRowModel[];
  columns: GridColDef[];
  settingsPrefix: string;
  defaultFilterModel?: GridFilterModel;
  defaultColumnVisibilityModel?: GridColumnVisibilityModel;
  columnGroupingModel?: GridColumnGroupingModel;
  headerHeight?: number;
  filtersKey?: string;
  visibilityKey?: string;
  densityKey?: string;
};

type Density = DataGridProProps["density"];

export const DataGrid: FC<DataGridProps> = ({
  rows,
  columns,
  columnGroupingModel,
  headerHeight,
  settingsPrefix,
  defaultFilterModel,
  defaultColumnVisibilityModel,
  filtersKey = [settingsPrefix, "filters"].join("."),
  visibilityKey = [settingsPrefix, "visibility"].join("."),
  densityKey = [settingsPrefix, "density"].join("."),
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [filters, setFilters] = usePersistentState<GridFilterModel | null>(
    filtersKey,
    null
  );

  const [columnVisibilityModel, setColumnVisibilityModel] =
    usePersistentState<GridColumnVisibilityModel | null>(visibilityKey, null);

  const [persistedDensity, setPersistedDensity] = usePersistentState<Density | undefined>(
    densityKey,
    "standard"
  );

  const handleStateChange = useCallback(
    (state: { density: Density }) => {
      if (state.density !== persistedDensity) {
        setPersistedDensity(state.density);
      }
    },
    [persistedDensity, setPersistedDensity]
  );

  const initialState = useMemo(() => {
    return { density: persistedDensity };
  }, [persistedDensity]);

  return (
    <TableContainer style={{ width: "100%", overflowX: "auto" }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        columnHeaderHeight={headerHeight || 56}
        slots={{
          toolbar: DataGridToolbar,
        }}
        slotProps={{
          panel: {
            anchorEl,
          },
          toolbar: {
            setAnchorEl,
            setColumnVisibilityModel,
            setFilters,
            filterModel: filters,
            columnVisibilityModel,
          },
        }}
        initialState={initialState}
        onStateChange={handleStateChange}
        filterModel={filters || defaultFilterModel}
        onFilterModelChange={setFilters}
        columnVisibilityModel={columnVisibilityModel || defaultColumnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        {...rest}
      />
    </TableContainer>
  );
};

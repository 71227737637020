import React, { FC } from "react";
import { Button, Grid, Paper } from "@mui/material";

import { Case } from "auditaware-types";

import CaseOverview from "../Case/CaseOverview";
import CaseNotes from "../Case/CaseNotes";
import CaseAttachments from "../Case/CaseAttachments";
import CasesTable from "./CasesTable";
import BoxTop from "../BoxTop";
import BoxHeader from "../BoxHeader";
import { EditNote } from "@mui/icons-material";
import { Drawer, useDrawerControls } from "auditaware-ui";
import EditResultsSummary from "../Case/EditResults";
import OverviewBox from "../OverviewBox";
import { Item, List } from "../List";
import EditFees from "../Case/EditFees";
import useFormattedDate from "../../../hooks/useFormattedDate";

type CaseDetailProps = {
  case?: Case;
  cases?: Case[];
};

const CaseDetails: FC<CaseDetailProps> = ({ case: caseData, cases = [] }) => {
  if (!caseData) {
    return null;
  }

  const feeInvoicedDate = useFormattedDate(caseData.feeInvoicedOn);
  const resultsLetterSentOnDate = useFormattedDate(caseData.resultsLetterSentOn);
  const reviewCompletedOnDate = useFormattedDate(caseData.reviewCompletedOn);

  const [feesDrawerProps, openFeesDrawer, closeFeesDrawer] = useDrawerControls({
    title: "Edit Fees",
  });

  const [resultsDrawerProps, openResultsDrawer, closeResultsDrawer] = useDrawerControls({
    title: "Edit Results Summary",
  });

  const safeReviewedByName =
    caseData.reviewedBy?.displayName || caseData.reviewedBy?.email;

  const { id: caseId } = caseData;

  return (
    <>
      <CaseOverview caseData={caseData} />
      <Grid container spacing={2}>
        <Grid item xs md={6} lg={6}>
          <CaseNotes caseId={caseId} notes={caseData.notes} />
        </Grid>
        <Grid item xs md={6} lg={6}>
          <CaseAttachments caseId={caseId} attachments={caseData.attachments} />
        </Grid>
      </Grid>
      <CasesTable cases={cases}/>
      <Grid item xs={12}>
        <Grid marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs md={8} lg={8}>
              <Paper>
                <BoxTop>
                  <BoxHeader>Result Summary</BoxHeader>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<EditNote/>}
                    onClick={openResultsDrawer}
                  >
                    Edit
                  </Button>
                  <Drawer {...resultsDrawerProps}>
                    <EditResultsSummary
                      caseData={caseData}
                      closeDrawer={closeResultsDrawer}/>
                  </Drawer>
                </BoxTop>
                <OverviewBox>
                  <Grid container spacing={2}>
                    <Grid item md lg={6}>
                      <List>
                        <Item
                          primary="Result"
                          secondary={caseData.result?.name}
                        />
                        <Item
                          primary="Result Summary"
                          secondary={caseData.resultSummary}
                        />
                        <Item
                          primary="Reviewed By"
                          secondary={safeReviewedByName}
                        />
                      </List>
                    </Grid>
                    <Grid item md lg={6}>
                      <List>
                        <Item
                          primary="Result Letter Sent"
                          secondary={resultsLetterSentOnDate}/>
                        <Item
                          primary="Reviewed Completed"
                          secondary={reviewCompletedOnDate}/>
                      </List>
                    </Grid>
                  </Grid>
                </OverviewBox>
              </Paper>
            </Grid>

            <Grid item xs md={4} lg={4}>
              <Paper>
                <BoxTop>
                  <BoxHeader>Fees</BoxHeader>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<EditNote/>}
                    onClick={openFeesDrawer}
                  >
                    Edit
                  </Button>
                  <Drawer {...feesDrawerProps}>
                    <EditFees caseData={caseData} closeDrawer={closeFeesDrawer}/>
                  </Drawer>
                </BoxTop>
                <OverviewBox>
                  <Grid container spacing={2}>
                    <Grid item md lg={6}>
                      <List>
                        <Item primary="Fee Amount" secondary={caseData.feeAmount || ""}/>
                        <Item primary="Fee Invoiced" secondary={feeInvoicedDate || ""}/>
                        <Item primary="Fee Size" secondary={caseData.feeSize}/>
                      </List>
                    </Grid>
                  </Grid>
                </OverviewBox>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CaseDetails;

import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { Case } from "auditaware-types";

import StyledPaper from "../StyledPaper";

import useCaseColumns from "../../../hooks/useCaseColumns";

type CasesTableProps = {
  cases?: Case[];
};

const NoRows = () => (
  <Box sx={{ mt: 5, textAlign: "center", alignItems: "center" }}>
    <p>No other cases to display. Create one by clicking the + button.</p>
  </Box>
);

const CasesTable: FC<CasesTableProps> = ({ cases = [] }) => {
  const { caseId = "" } = useParams();
  const caseColumns = useCaseColumns();

  const otherCases = useMemo(() => cases.filter((c) => c.id !== caseId), [cases, caseId]);

  return (
    <Grid item xs md={12} lg={12}>
      <StyledPaper>
        <Box mb={3}>
          <DataGridPro
            rows={otherCases}
            columns={caseColumns}
            slots={{ noRowsOverlay: NoRows }}
          />
        </Box>
      </StyledPaper>
    </Grid>
  );
};

export default CasesTable;

import { FC } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Drawer, useDrawerControls } from "auditaware-ui";

import CreateCase from "./CreateCase";

const NewCase: FC = () => {
  const [drawerProps, openDrawer, closeDrawer] = useDrawerControls({
    title: "Create Case",
  });

  return (
    <>
      <Fab
        sx={{ position: "fixed", bottom: "4rem", right: "6rem", boxShadow: 8 }}
        color="info"
        aria-label="add case"
        onClick={openDrawer}
        title="Add Case"
        type="button"
      >
        <AddIcon />
      </Fab>
      <Drawer {...drawerProps}>
        <CreateCase closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};

export default NewCase;
